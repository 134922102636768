/* Positions Text and form fields nicely in the middle with some spacing. */
.mc__form {
  display: grid;
  grid-template-rows: min-content min-content;
  grid-row-gap: 20px;
  justify-content: stretch;
  align-items: center;
  transition: height .2s ease;
}

/* Styles the h3 */
.mc__title {
  max-width: 235px;
  text-align: center;

  /* Replace with your brand's main text color */
  color: #ffffff;
  padding: 10px auto 10px;
}

/* Positions the form in the middle of the modal and ensures the content doesn't jump as status messages get added/removed */
.mc__form-container {
  width: 100%;
  margin: 0 auto;
  transition: height .2s ease;
}

/* Seperates the input fields vertically */
.mc__field-container {
  display: grid;
  grid-row-gap: 30px;
}

.mc__alert--sending {
  text-align: center;
  color: green;
  -webkit-animation: fade-loop 0.8s ease-in-out infinite alternate-reverse both;
  animation: fade-loop 0.8s ease-in-out infinite alternate-reverse both;
}
.mc__alert--success {
  text-align: center;
  color: #ffffff;
}
.mc__alert--error {
  text-align: center;
  color: red;
  font-weight: bold;
}


/* ----------------------------------------------
 * Generated by Animista on 2021-4-1 16:18:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-loop
 * ----------------------------------------
 */
@-webkit-keyframes fade-loop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-loop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}