.item-container {
  margin: auto;
  max-height: 250px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-image {
  flex-basis: 60%;
  
  border-radius: 50%;
  padding: 20px;
  max-width: 160px;
  max-height: 160px;
}

.item-info {
  flex-basis: 30%;
}

.item-action {
  flex-basis: 10%;
}