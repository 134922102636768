th {
  text-decoration: none;
  color: #fff;
  fill: #fff;
  padding: 5px;
}

td {
  text-decoration: none;
  color: #fff;
  fill: #fff;
}

label {
  background-color: #444;
  padding: 5px;
  flex-basis: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.collapsible {
  background-color: #444;
  color: #fff;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.collapsible:hover {
  background-color: #3e594a;
}