code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

video {
  width: calc(240px + 2vmin);
  height: auto;
}

.back-button {
  background-color: #4CAF50; 
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

* {
  box-sizing: border-box;
  font-family: "oblique";
}

.background-black {
  background-clip: padding-box;
  background-color: #000000;
}

.background-wallpaper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  z-index: -2;
  background-size: cover;
  background-position: center center;
  background-image: url("./../resources/images/270224.png");
}

.background-wallpaper-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  z-index: -1;
  background-size: cover;
  background-position: center center;
  opacity: 0.9;
  background-color: #000;
}

.contents-container {
  display: flex;
  flex-direction: column;
  height: 70vh; 
  max-height: -webkit-fill-available;
}

@font-face {
  font-family: "oblique";
  src: local("oblique"),
  url("./../resources/fonts/Courier-Oblique.otf") format("truetype");
}

.c-black:hover {
  background-color: #3e594a;
  color: #fff;
  fill: #fff;
}

.c-black {
  text-decoration: none;
  color: #000;
  fill: #000f;
}

.c-white {
  text-decoration: none;
  color: #fff;
  fill: #fff;
}

.disabled {
  pointer-events: none;
}

.button {
  background-color: transparent;
  padding: 5px 15px;
  border: none;
  font-weight: bolder;
  font-size: large;
}
button:hover {
  background-color: #3e594a;
}

dialog {
  background-color: #444;
}

hr {
  border: 1px solid #3e594a;
  width: 100%;
  margin-bottom: 50px;
}

.text-center {
  text-align: center;
}