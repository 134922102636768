nav.nav-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

img.nav-bar-img {
  width: 120px;
  height: 45px;
  margin: 20px;
}

ul.nav-bar {
  /* display: inline-block;
  text-align:center;
  width: 100%;
  list-style-type: none;
  padding-inline-start: 10px;
  padding-inline-end: 10px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}

li.nav-bar-item {
  /* width:15%;
  text-align: center;
  
  display: inline-block;
  float: none;
  text-align: center; */
  flex-basis: auto;
  min-width: 50px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
} 

ul.link-icon-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  margin-left: auto;
  margin-right: auto;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
}

li.link-icon-item {
  margin-left: 10px;
  margin-right: 10px;
} 

div.main-content {
  display: flex;
  flex-direction: column;
}

div.link-container {
  display: flex;
  flex-direction: column;
}